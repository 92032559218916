import React, { useState } from 'react';
import ForecastTable from './components/ForecastTable'
import LoginScreen from './components/LoginScreen'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import './App.css';

function App() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    const handleNotAuthenticated = () => {
        sessionStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    const token = sessionStorage.getItem('token');
    if (token !== null && !isAuthenticated) {
        setIsAuthenticated(true)
    }

    if (!isAuthenticated) {
        return <LoginScreen onLogin={handleLogin}/>
    }

    return (
        <div className="App">
            <Box p={3}>
                <Typography variant="h1" gutterBottom>Fluke Demand Forecast</Typography>
                <ForecastTable onNotAuthenticated={handleNotAuthenticated}/>
            </Box>
        </div>
    );
}

export default App;
