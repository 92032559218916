import React, { useState } from 'react';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';

const axios = require('axios');

const LoginScreen = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleLogin = () => {
        axios.post(process.env.REACT_APP_API_URL + 'api-token-auth/', {
            username: username,
            password: password
        }).then(response => {
            sessionStorage.setItem('token', response.data.token);
            props.onLogin();
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <Box>
            <Typography variant="h1">Fluke Demand Forecast Login</Typography>
            <Box mt={3}>
                <TextField
                    label="Username"
                    value={username}
                    onChange={handleUsernameChange}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    label="Password"
                    value={password}
                    type="password"
                    onChange={handlePasswordChange}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>

            <Button onClick={handleLogin} variant="contained">Log In</Button>

        </Box>
    )


}

export default LoginScreen;
